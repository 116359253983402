var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: true,
              showCheckbox: false,
            },
            on: {
              getTreeAdd: _vm.treeMenuAdd,
              getTreeEdit: _vm.treeMenuEdit,
              getTreeDelete: _vm.treeNodeDel,
              getNodeClick: _vm.treeNodeClick,
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(
                    `cip.plat.reportcenter.report.title.indexHeadTitle`
                  ),
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-add-tabs": _vm.headAddTabs,
                  "head-role-setting": _vm.headRoleSetting,
                },
              }),
              _c(
                "div",
                { staticClass: "headers" },
                [
                  _c("grid-head-layout", {
                    ref: "gridHeadLayout",
                    attrs: {
                      "grid-head-btn": _vm.gridHeadBtn,
                      "search-columns": _vm.searchColumns,
                    },
                    on: {
                      "grid-head-search": _vm.gridHeadSearch,
                      "grid-head-empty": _vm.gridHeadEmpty,
                    },
                  }),
                ],
                1
              ),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "grid-row-detail-click": _vm.rowDetail,
                  "row-view-report": _vm.rowViewReport,
                  "row-remove": _vm.rowRemove,
                  "row-edit": _vm.rowEdit,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showTreeDialog
        ? _c(
            "CommonDialog",
            {
              attrs: {
                showFullscreen: true,
                modal: false,
                width: "60%",
                dialogTitle: _vm.$t(
                  `cip.plat.reportcenter.report.title.headCateTitle`
                ),
              },
              on: {
                cancel: function ($event) {
                  _vm.showTreeDialog = false
                },
                confirm: _vm.treeNodeSave,
              },
            },
            [
              _c("avue-form", {
                ref: "formLayout",
                attrs: { option: _vm.treeDialogOption },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.settingVisible
        ? _c(
            "CommonDialog",
            {
              attrs: {
                width: "30%",
                dialogTitle: _vm.$t(
                  `cip.plat.reportcenter.report.title.roleNameTitle`
                ),
              },
              on: {
                cancel: function ($event) {
                  _vm.settingVisible = false
                },
                confirm: _vm.handleSubmit,
              },
            },
            [
              _c("el-tree", {
                ref: "roleReportSetting",
                attrs: {
                  data: _vm.roleTreeData,
                  "show-checkbox": "",
                  "node-key": "id",
                  "default-checked-keys": _vm.roleReportObj,
                  props: _vm.props,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.reportViewDialog
        ? _c(
            "CommonDialog",
            {
              attrs: {
                showBtn: false,
                width: "80%",
                dialogTitle: _vm.$t(
                  "cip.plat.reportcenter.report.btn.reportView"
                ),
              },
              on: {
                cancel: function ($event) {
                  _vm.reportViewDialog = false
                },
              },
            },
            [
              _c("iframe", {
                ref: "iframe",
                staticStyle: { "text-align": "center" },
                attrs: {
                  src: _vm.iframeUrl,
                  id: "iframe",
                  width: "100%",
                  height: "500",
                },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }