<template>
  <div>
    <el-container>
      <CommonTree
          :treeData="treeData"
          :defaultProps="defaultProps"
          :searchTitle="searchTitle"
          :isShowdig="true"
          :showCheckbox="false"
          @getTreeAdd="treeMenuAdd"
          @getTreeEdit="treeMenuEdit"
          @getTreeDelete="treeNodeDel"
          @getNodeClick="treeNodeClick"
      />
      <el-main>
        <head-layout
            :head-btn-options="headBtnOptions"
            :head-title="$t(`cip.plat.reportcenter.report.title.indexHeadTitle`)"
            @head-remove="headRemove"
            @head-add-tabs="headAddTabs"
            @head-role-setting="headRoleSetting"
        ></head-layout>
        <div class="headers">
          <grid-head-layout
              ref="gridHeadLayout"
              :grid-head-btn="gridHeadBtn"
              :search-columns="searchColumns"
              @grid-head-search="gridHeadSearch"
              @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
        </div>
        <grid-layout
            ref="gridLayOut"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="page.total"
            :page="page"
            @page-current-change="onLoad"
            @page-size-change="onLoad"
            @page-refresh-change="onLoad"
            @grid-row-detail-click="rowDetail"
            @row-view-report="rowViewReport"
            @row-remove="rowRemove"
            @row-edit="rowEdit">
        </grid-layout>
      </el-main>
    </el-container>
    <CommonDialog v-if="showTreeDialog" :showFullscreen="true" :modal="false" width="60%" :dialogTitle="$t(`cip.plat.reportcenter.report.title.headCateTitle`)" @cancel="showTreeDialog = false" @confirm="treeNodeSave">
      <avue-form :option="treeDialogOption" v-model="form" ref=formLayout></avue-form>
    </CommonDialog>
    <CommonDialog v-if="settingVisible" width="30%" :dialogTitle="$t(`cip.plat.reportcenter.report.title.roleNameTitle`)" @cancel="settingVisible = false" @confirm="handleSubmit">
     <el-tree :data="roleTreeData"
               show-checkbox node-key="id"
               ref="roleReportSetting"
               :default-checked-keys="roleReportObj"
               :props="props">
      </el-tree>
    </CommonDialog>
    <CommonDialog v-if="reportViewDialog" :showBtn="false" width="80%" :dialogTitle="$t('cip.plat.reportcenter.report.btn.reportView')" @cancel="reportViewDialog = false">
     <iframe
          ref="iframe"
          :src="iframeUrl"
          id="iframe"
          width="100%"
          height="500"
          style="text-align: center"
      ></iframe>
    </CommonDialog>
  </div>
</template>

<script>

import {remove as removeTree, saveOrUpdate as saveOrUpdateTree} from "@/api/system/reportCate";

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

import {
  remove,
  getList, submitRoleSetting, getRoleSetting
} from '@/api/system/report'
import TreeLayout from "@/views/components/layout/tree-layout";
import CommonTree from "@/views/components/com_tree/index"
import ServerNameEnum from "@/util/ServerNameEnum";
import {tree} from "@/api/system/reportCate";
import {getRolesTree} from "@/api/system/role";
import website from "@/config/website";
import {getDictionaryCode} from "@/api/system/dictbiz"
import CommonDialog from "@/components/CommonDialog";
const CONTROLLER_NAME = "reportCate";
export default {
  name: "report",
  components: {
    GridLayout,
    HeadLayout,
    TreeLayout,
    CommonTree,
    CommonDialog
  },
  data() {
    return {
      typeData:[],
      reportViewDialog: false,
      iframeUrl: '',
      iframeUrls: `${process.env.VUE_APP_BASE_RQREPORTURL}/demo/reportJsp/showReport.jsp?rpx=`,
      props: {
        label: "title",
        value: "id"
      },
      btnLoading: false,
      settingVisible: false,
      roleTreeData: [],
      roleReportObj: [],
      treeReadOnlyFlag: '',
      form: {
        parentId: '',
      },
      query: {
        reportTypeId: "",
      },
      showTreeDialog: false,
      fullscreen: false,
      node: {},   //左侧树node
      reportTypeId: '', // 点击左侧树的code
      treeData: [],
      defaultProps: {
        label: "reportCategoryName",
        value: "reportTypeId",
        key: "reportTypeId"
      },
      searchTitle: 'reportCategoryName',
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission", "userInfo"]),
    searchColumns() {
      return [
        {
          prop: "reportName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.reportcenter.report.field.reportName"),
        },
        {
          prop: "reportType",
          type: 'select',
          dataType: 'string',
          span: 4,
          dicData: this.typeData,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: this.$t(`cip.plat.reportcenter.report.field.reportType`)
        },
      ]
    },
    treeDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,

        column: [
          {
            label: this.$t("cip.plat.reportcenter.report.field.parentCode"),
            prop: "parentId",
            type: "tree",
            props: {
              label: 'reportCategoryName',
              value: 'id',
              key: 'parentId',
            },
            placeholder: this.$t(`cip.plat.reportcenter.report.field.parentCode`),
            dicUrl: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/tree`,
          },
          {
            label: this.$t(`cip.plat.reportcenter.report.field.reportTypeCode`),
            prop: "reportCategoryCode",
            maxlength: 50,
            readonly: this.treeReadOnlyFlag,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.reportcenter.report.field.reportTypeCode`),
                trigger: ["blur", "change"],
              },
              {
                pattern: /^[^\s]+(\s+[^\s]+)*$/,
                message: this.$t('cip.cmn.rule.deleteWarning'),
              },
            ],
          },
          {
            label: this.$t(`cip.plat.reportcenter.report.field.reportTypeName`),
            prop: "reportCategoryName",
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.reportcenter.report.field.reportTypeName`),
                trigger: "blur",
              },
              {
                pattern: /^[^\s]+(\s+[^\s]+)*$/,
                message: this.$t('cip.cmn.rule.deleteWarning'),
              },
            ],
          },
          {
            label: this.$t(`cip.plat.reportcenter.report.field.remark`),
            prop: "remark",
            type: "textarea",
            overHidden: "15",
            span: 24,
            minRows: 2,
            maxRows: 2,
            maxlength: 200,
            showWordLimit: true,
          },
        ]
      }
    },
    treeOptions() {
      return {
        title: this.$t(`cip.plat.reportcenter.report.title.indexHeadTitle`),
        defaultExpandAll: true,
        nodeKey: 'id',
        size: 'mini',
        menu: true,
        lazy: false,
        dialogType: 'drawer',
        addBtn: false,
        editBtn: false,
        delBtn: false,
        props: {
          labelText: this.$t(`cip.plat.reportcenter.report.title.indexHeadTitle`),
          id: "id",
          label: 'reportTypeId',
          value: 'id',
          children: 'children'
        },
      };
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        linklabel: "reportName",
        menuWidth: 200,
        column: [
          {
            label: this.$t('cip.plat.reportcenter.report.field.sort'),
            prop: 'sort',
            width: 80,
          },
          {
            label: this.$t('cip.plat.reportcenter.report.field.reportName'),
            prop: 'reportName',
            overHidden: true,
            align:'left',
          },
          {
            label: this.$t('cip.plat.reportcenter.report.field.roleName'),
            prop: 'roleName',
            overHidden: true,
            align:'left',
          },
          {
            label: this.$t('cip.plat.reportcenter.report.field.reportType'),
            prop: 'reportType',
            width:'150',
            type: 'select',
            align: "center",
            dataType: "string",
            dicUrl: `${baseUrl}REPORT_TYPE`,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            overHidden: true,
          },
        ],
      }
    },
    gridRowBtn() {
      let btn = []
      if (this.permission.report_edit) {
        btn.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        })

        btn.push({
          label: this.$t('cip.plat.reportcenter.report.btn.reportView'),
          emit: "row-view-report",
          type: "button",
          icon: ""
        })
      }
      if (this.permission.report_delete) {
        btn.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        })
      }
      return btn;
    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      let btn = []
      if (this.permission.report_add) {
        btn.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          // btnOptType: 'add',
        })
      }
      if (this.permission.report_delete) {
        btn.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          // btnOptType: 'remove',
        })
      }
      if (this.userInfo.role_name.includes('administrator')) {
        btn.push({
          label: this.$t('cip.plat.reportcenter.report.btn.settingsBtn'),
          emit: "head-role-setting",
          type: "button",
          icon: "",
          remark: 'admin',
        })
      }
      return btn
    },
  },
  mounted() {
    this.onLoad(this.page, {});
    if (this.userInfo.role_name.includes('administrator')) {
      this.getRoleTreeByUserType();
    }
  },
  methods: {
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    treeMenuAdd() {
      this.form = {};
      this.form.parentId = this.node.id;
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },
    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = true;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },
    // 新增左侧树节点
    treeNodeSave() {
      this.$refs.formLayout.validate((valid) => {
        if (valid) {
          saveOrUpdateTree(this.form).then(res => {
            const {msg} = res.data;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            this.initTree();
            this.showTreeDialog = false;
          })
        }
      })
    },
    initTree() {
      tree({parentId: 0}).then(res => {
        const {data} = res.data;
        this.treeData = data;
      });
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.hasChildren) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.hasChildrenWarning'))
        return
      }

      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        removeTree(data.id).then(res => {
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
          this.initTree()
          done()
        })
      }).catch(() => {
      });
    },
    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      const {id} = node;
      this.reportTypeId = id;
      this.page.total = 0;
      this.onLoad(this.page, {});
    },
    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      if (selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
          .then(() => {
            if (ids.length === 0) {
              //只选中了空数据
              let index = 0;
              selectionList.filter(e => !e.id).forEach(e => {
                this.$refs.gridLayOut.tableData.splice(e.$index - index, 1);
                index++
              })
              this.$refs.gridLayOut.selectionClear()
              return;
            }
            return remove(this.$func.join(ids))
                .then(() => {
                  this.onLoad(this.page, {});

                  this.$message({
                    type: "success",
                    message: this.$t('cip.cmn.msg.success.operateSuccess')
                  });
                  this.$refs.gridLayOut.selectionClear()
                });
          }).catch(() => {
      })
    },
    handleSubmit() {
      this.btnLoading = true
      let roleList = ""
      if (this.$refs.roleReportSetting.getCheckedKeys().length > 0) {
        roleList = this.$refs.roleReportSetting.getCheckedKeys().join(",");
      }
      submitRoleSetting(this.$refs.gridLayOut.selectionList[0].id, roleList)
          .then(() => {
            this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            this.settingVisible = false
            this.onLoad(this.page)
            this.btnLoading = false
          })
    },
    getRoleTreeByUserType() {
      getRolesTree(website.tenantId).then(res => {
        this.roleTreeData = res.data.data;
      });
    },
    headRoleSetting() {
      this.roleReportObj = []
      if (this.$refs.gridLayOut.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      } else if (this.$refs.gridLayOut.selectionList.length > 1) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectOneWarning'));
        return;
      }
      getRoleSetting(this.$refs.gridLayOut.selectionList[0].id).then((res) => {
        this.settingVisible = true
        this.roleReportObj = res.data.data
        this.$refs.roleReportSetting.setCheckedKeys(this.roleReportObj)

      })

    },
    headAddTabs() {
      if (!this.reportTypeId) {
        this.$message.warning(this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.reportcenter.report.field.reportTypeId'));
        return;
      }
      this.$router.push({
        path: `/report/reportEdit/add`,
        query: {
          type: 'add',
          node: this.node
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/report/reportEdit/edit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    rowDetail(row) {
      this.$router.push({
        path: `/report/reportEdit/view`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },
    rowViewReport(row) {
      console.log(row,'123')
      if ((row.reportFileName == '' || row.reportFileName == undefined) && (row.reportReviewPath == '' || row.reportReviewPath == undefined)) {
        this.$message.error(this.$t('cip.plat.reportcenter.report.msg.report'))
        return;
      }
        if(row.reportReviewPath){
            this.iframeUrl =  row.reportReviewPath
        }else {
            this.iframeUrl = this.iframeUrls + row.reportFileName
        }
      this.reportViewDialog = true
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t('cip.cmn.msg.success.operateSuccess')
            });
          });
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      this.query.reportTypeId = this.reportTypeId;
      getList(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.query)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }
  },
  created() {
    getDictionaryCode("REPORT_TYPE").then(res => {
      this.$refs.gridHeadLayout.searchColumns[1].dicData = res.data.data
    })
    this.onLoad(this.page, {});
    tree()
        .then((result) => {
          this.treeData = result.data.data;
          this.treeLoading = false;
        })
        .catch((err) => {
          this.treeLoading = true;
        });
  },
};
</script>
<style>
</style>
